import React, { Component } from "react";
import "./termsAndConditions.scss";
import { Helmet } from "react-helmet";

class termsAndConditions extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            ViuLive Terms and Conditions - User Agreement and Guidelines
          </title>
          <meta
            name="description"
            content="Stay updated on ViuLive Terms and Conditions, covering user conduct, data policies, and safe, legal live streaming practices."
          />
          <meta
            name="keywords"
            content="Multi camera live streaming, Event broadcasting, Multi camera broadcasting service, ViuLive, Live streaming, Irl live streaming, Live Broadcasting,Multi camera live broadcasting and viewing, Multi live broadcasting, Multi viewing, Live event streaming, Group broadcasting,Multiple locations live steaming,Live streaming app,Live streaming software,Live streaming platform, Live streaming service, Event broadcasting,Live TV streaming,Live streaming concert"
          />
          <link rel="canonical" href="https://viulive.com/terms" />
        </Helmet>
        <div
          style={
            this.props.inRegister
              ? {
                  height: "400px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingTop: "20px",
                }
              : {}
          }
        >
          <div
            className={`${
              this.props.inRegister
                ? "registerTermsContainer"
                : "termsContainer"
            }`}
          >
            {/* <p
            className={`${
              this.props.inRegister
                ? "registerTermsUpdateDate"
                : "termsUpdateDate"
            }`}
          >
            Last Updated July 7, 2023
          </p> */}
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>1. Agreement to Terms</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              1.1 These Terms and Conditions constitute a legally binding
              agreement made between you, whether personally or on behalf of an
              entity (<b className="subHeaderterms">you</b>), and
              <b className="subHeaderterms"> ViuCom Corp</b>.,located at
              <b className="subHeaderterms"> Bellevue , WA (we, us)</b>,
              concerning your access to and use of the{" "}
              <a
                className="termLink"
                target="_blank"
                href="https://www.viulive.com/"
                rel="noopener noreferrer"
              >
                www.viulive.com
              </a>
              &nbsp; website as well as any related mobile applications (the
              <b className="subHeaderterms"> Site</b>).
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              The Site provides the following services:
              <b className="subHeaderterms">
                live video streaming, IRL (Services)
              </b>
              . You agree that by accessing the Site and/or Services, you have
              read, understood, and agree to be bound by all of these Terms and
              Conditions.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b className="subHeaderterms">
                If you do not agree with all of these Terms and Conditions, then
                you are prohibited from using the Site and Services and you must
                discontinue use immediately
              </b>
              . We recommend that you print a copy of these Terms and Conditions
              for future reference.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              1.2 The supplemental policies set out in Section 1.7 below, as
              well as any supplemental terms and condition or documents that may
              be posted on the Site from time to time, are expressly
              incorporated by reference.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              1.3 We may make changes to these Terms and Conditions at any time.
              The updated version of these Terms and Conditions will be
              indicated by an updated “Revised” date and the updated version
              will be effective as soon as it is accessible. You are responsible
              for reviewing these Terms and Conditions to stay informed of
              updates. Your continued use of the Site represents that you have
              accepted such changes.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              1.4 We may update or change the Site from time to time to reflect
              changes to our products, our users' needs and/or our business
              priorities.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              1.5 Our site is directed to people residing in anywhere this site
              is considered legal by the authorities. The information provided
              on the Site is not intended for distribution to or use by any
              person or entity in any jurisdiction or country where such
              distribution or use would be contrary to law or regulation or
              which would subject us to any registration requirement within such
              jurisdiction or country.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              1.6 The Site is intended for users who are at least 18 years old.
              If you are under the age of 18, you are not permitted to register
              for the Site or use the Services without parental permission.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              1.7 Additional policies which also apply to your use of the Site
              include:
            </p>
            <ul className="termsList">
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Our Privacy Notice, which sets out the terms on which we process
                any personal data we collect from you, or that you provide to
                us. By using the Site, you consent to such processing and you
                warrant that all data provided by you is accurate.
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Our Acceptable Use Policy (section 14), which sets out the
                permitted uses and prohibited uses of the Site. When using the
                Site, you must comply with this Acceptable Use Policy.
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Our Cookie Policy, which sets out information about the cookies
                on the Site.
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                If you purchase from the Site, our terms and conditions of
                eCommerce will apply. If you wish to use such Services, you
                will, in addition to our Terms and Conditions, also be subject
                to our Terms and conditions of eCommerce.
              </li>
            </ul>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>2. Acceptable Use</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              2.1 Our full Acceptable Use Policy, sets out all the permitted
              uses and prohibited uses of this site.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              2.2 You may not access or use the Site for any purpose other than
              that for which we make the site and our services available. The
              Site may not be used in connection with any commercial endeavors
              except those that are specifically endorsed or approved by us.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              2.3 As a user of this Site, you agree not to:
            </p>
            <ul className="termsList">
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Falsely imply a relationship with us or another company with
                whom you do not have a relationship
              </li>
            </ul>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>3. Information you provide to us</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              3.1 You represent and warrant that: (a) all registration
              information you submit will be true, accurate, current, and
              complete and relate to you and not a third party; (b) you will
              maintain the accuracy of such information and promptly update such
              information as necessary; (c) you will keep your password
              confidential and will be responsible for all use of your password
              and account; (d) you have the legal capacity and you agree to
              comply with these Terms and Conditions; and (e) you are not a
              minor in the jurisdiction in which you reside, or if a minor, you
              have received parental permission to use the Site.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              If you know or suspect that anyone other than you knows your user
              information (such as an identification code or user name) and/or
              password you must promptly notify us at{" "}
              <a
                className="termLink"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:info@viulive.com"
              >
                info@viulive.com
              </a>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              3.2 If you provide any information that is untrue, inaccurate, not
              current or incomplete, we may suspend or terminate your account.
              We may remove or change a user name you select if we determine
              that such user name is inappropriate.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>4. Content you provide to us</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              4.1 There may be opportunities for you to post content to the Site
              or send feedback to us (
              <b className="subHeaderterms">User Content</b>). You understand
              and agree that your User Content may be viewed by other users on
              the Site, and that they may be able to see who has posted that
              User Content.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              4.2 You further agree that we can use your User Content for any
              other purposes whatsoever in perpetuity without payment to you,
              and combine your User Content with other content for use within
              the Site and otherwise. We do not have to attribute your User
              Content to you. When you upload or post content to our site, you
              grant us the following rights to use that content:
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              4.3 In posting User Content, including reviews or making contact
              with other users of the Site you shall comply with our Acceptable
              Use Policy.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              4.4 You warrant that any User Content does comply with our
              Acceptable Use Policy, and you will be liable to us and indemnify
              us for any breach of that warranty. This means you will be
              responsible for any loss or damage we suffer as a result of your
              breach of this warranty.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              4.5 We have the right to remove any User Content you put on the
              Site if, in our opinion, such User Content does not comply with
              the Acceptable Use Policy.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              4.6 We are not responsible and accept no liability for any User
              Content including any such content that contains incorrect
              information or is defamatory or loss of User Content. We accept no
              obligation to screen, edit or monitor any User Content but we
              reserve the right to remove, screen and/or edit any User Content
              without notice and at any time. User Content has not been verified
              or approved by us and the views expressed by other users on the
              Site do not represent our views or values
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              4.7 If you wish to complain about User Content uploaded by other
              users please contact us at{" "}
              <a
                className="termLink"
                target="_blank"
                href="mailto:info@viulive.com"
                rel="noopener noreferrer"
              >
                info@viulive.com
              </a>
              <span> or use the report button.</span>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>5. Our content</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              5.1 Unless otherwise indicated, the Site and Services including
              source code, databases, functionality, software, website designs,
              audio, video, text, photographs, and graphics on the Site (
              <b className="subHeaderterms">Our Content</b>) are owned or
              licensed to us, and are protected by copyright and trade mark
              laws.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              5.2 Except as expressly provided in these Terms and Conditions, no
              part of the Site, Services or Our Content may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              5.3 Provided that you are eligible to use the Site, you are
              granted a limited license to access and use the Site and Our
              Content and to download or print a copy of any portion of the
              Content to which you have properly gained access solely for your
              personal, non-commercial use.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              5.4 You shall not (a) try to gain unauthorized access to the Site
              or any networks, servers or computer systems connected to the
              Site; and/or (b) make for any purpose including error correction,
              any modifications, adaptions, additions or enhancements to the
              Site or Our Content, including the modification of the paper or
              digital copies you may have downloaded.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              5.5 We shall (a) prepare the Site and Our Content with reasonable
              skill and care; and (b) use industry standard virus detection
              software to try to block the uploading of content to the Site that
              contains viruses.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              5.6 The content on the Site is provided for general information
              only. It is not intended to amount to advice on which you should
              rely. You must obtain professional or specialist advice before
              taking, or refraining from taking, any action on the basis of the
              content on the Site.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              5.7 Although we make reasonable efforts to update the information
              on our site, we make no representations, warranties or guarantees,
              whether express or implied, that Our Content on the Site is
              accurate, complete or up to date.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>6. Link to third party content</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              6.1 The Site may contain links to websites or applications
              operated by third-parties. We do not have any influence or control
              over any such third-party websites or applications or the
              third-party operator. We are not responsible for and do not
              endorse any third-party websites or applications or their
              availability or content.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              6.2 We accept no responsibility for adverts contained within the
              Site. If you agree to purchase goods and/or services from any
              third party who advertises in the Site, you do so at your own
              risk. The advertiser, and not us, is responsible for such goods
              and/or services and if you have any questions or complaints in
              relation to them, you should contact the advertiser.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              6.3 Our Restream application utilizes YouTube API services. By
              using the features of this Restream application that utilize
              YouTube API services, you are also agreeing to be bound by the
              YouTube Terms of Service located at{" "}
              <a
                style={{
                  color: "#05659d",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                href="https://www.youtube.com/t/terms"
              >
                Youtube Terms page
              </a>
              .
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              6.4 Our application’s Privacy Policy explains how we use YouTube
              API services and how we handle your data. By using our
              application, you acknowledge and agree to our Privacy Policy. You
              can review our{" "}
              <a
                style={{
                  color: "#05659d",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                href="https://viulive.com/privacy"
              >
                Privacy Policy
              </a>
              . For information on how to manage and revoke API Client's access
              to your data, you can visit the{" "}
              <a
                style={{
                  color: "#05659d",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                href="https://security.google.com/settings/security/permissions"
              >
                Google security settings page
              </a>
              .
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>7. Site Management</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              7.1 We reserve the right at our sole discretion, to (1) monitor
              the Site for breaches of these Terms and Conditions; (2) take
              appropriate legal action against anyone in breach of applicable
              laws or these Terms and Conditions; (3) refuse, restrict access to
              or availability of, or disable (to the extent technologically
              feasible) any of your Contributions; (4) remove from the Site or
              otherwise disable all files and content that are excessive in size
              or are in any way a burden to our systems; and (5) otherwise
              manage the Site in a manner designed to protect our rights and
              property and to facilitate the proper functioning of the Site and
              Services.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              7.2 We do not guarantee that the Site will be secure or free from
              bugs or viruses.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              7.3 You are responsible for configuring your information
              technology, computer programs and platform to access the Site and
              you should use your own virus protection software.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>8. Modifications to and availability of the Site</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              8.1 We reserve the right to change, modify, or remove the contents
              of the Site at any time or for any reason at our sole discretion
              without notice. We also reserve the right to modify or discontinue
              all or part of the Services without notice at any time.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              8.2 We cannot guarantee the Site and Services will be available at
              all times. We may experience hardware, software, or other problems
              or need to perform maintenance related to the Site, resulting in
              interruptions, delays, or errors. You agree that we have no
              liability whatsoever for any loss, damage, or inconvenience caused
              by your inability to access or use the Site or Services during any
              downtime or discontinuance of the Site or Services. We are not
              obliged to maintain and support the Site or Services or to supply
              any corrections, updates, or releases.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              8.3 There may be information on the Site that contains
              typographical errors, inaccuracies, or omissions that may relate
              to the Services, including descriptions, pricing, availability,
              and various other information. We reserve the right to correct any
              errors, inaccuracies, or omissions and to change or update the
              information at any time, without prior notice.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>9. Disclaimer/Limitation of Liability</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              9.1 The Site and Services are provided on an as-is and
              as-available basis. You agree that your use of the Site and/or
              Services will be at your sole risk except as expressly set out in
              these Terms and Conditions. All warranties, terms, conditions and
              undertakings, express or implied (including by statute, custom or
              usage, a course of dealing, or common law) in connection with the
              Site and Services and your use thereof including, without
              limitation, the implied warranties of satisfactory quality,
              fitness for a particular purpose and non-infringement are excluded
              to the fullest extent permitted by applicable law.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              We make no warranties or representations about the accuracy or
              completeness of the Site’s content and are not liable for any (1)
              errors or omissions in content: (2) any unauthorized access to or
              use of our servers and/or any and all personal information and/or
              financial information stored on our server; (3) any interruption
              or cessation of transmission to or from the site or services; and/
              or (4) any bugs, viruses, trojan horses, or the like which may be
              transmitted to or through the site by any third party. We will not
              be responsible for any delay or failure to comply with our
              obligations under these Terms and Conditions if such delay or
              failure is caused by an event beyond our reasonable control.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>10. Term and Termination</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              10.1 These Terms and Conditions shall remain in full force and
              effect while you use the Site or Services or are otherwise a user
              of the Site, as applicable. You may terminate your use or
              participation at any time, for any reason, by following the
              instructions for terminating user accounts in your account
              settings, if available, or by contacting us at{" "}
              <a
                className="termLink"
                target="_blank"
                href="mailto:info@viulive.com"
                rel="noopener noreferrer"
              >
                info@viulive.com
              </a>
              .
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              10.2 Without limiting any other provision of these Terms and
              Conditions, we reserve the right to, in our sole discretion and
              without notice or liability, deny access to and use of the Site
              and the Services (including blocking certain IP addresses), to any
              person for any reason including without limitation for breach of
              any representation, warranty or covenant contained in these Terms
              and Conditions or of any applicable law or regulation.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              If we determine, in our sole discretion, that your use of the
              Site/Services is in breach of these Terms and Conditions or of any
              applicable law or regulation, we may terminate your use or
              participation in the Site and the Services or delete your profile
              and any content or information that you posted at any time,
              without warning, in our sole discretion.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              10.3 If we terminate or suspend your account for any reason set
              out in this Section 9, you are prohibited from registering and
              creating a new account under your name, a fake or borrowed name,
              or the name of any third party, even if you may be acting on
              behalf of the third party. In addition to terminating or
              suspending your account, we reserve the right to take appropriate
              legal action, including without limitation pursuing civil,
              criminal, and injunctive redress.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>11. Mobile Application</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              11.1 If you access the Services via a mobile application, then we
              grant you a revocable, non-exclusive, non-transferable, limited
              right to install and use the mobile application on wireless
              electronic devices owned or controlled by you, and to access and
              use the mobile application on such devices strictly in accordance
              with the terms and conditions of this license.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              11.2 The following terms apply when you use a mobile application
              obtained from either the Apple Store or Google Play (each an App
              Distributor) to access the Services:
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              (a) The license granted to you for our mobile application is
              limited to a non-transferable license to use the application on a
              device that utilizes the Apple iOS or Android operating system, as
              applicable, and in accordance with the usage rules set forth in
              the applicable App Distributor terms of service;
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              (b) We are responsible for providing any maintenance and support
              services with respect to the mobile application as specified in
              these Terms and Conditions or as otherwise required under
              applicable law. You acknowledge that each App Distributor has no
              obligation whatsoever to furnish any maintenance and support
              services with respect to the mobile application;
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              (c) In the event of any failure of the mobile application to
              conform to any applicable warranty, you may notify an App
              Distributor, and the App Distributor, in accordance with its terms
              and policies, may refund the purchase price, if any, paid for the
              mobile application, and to the maximum extent permitted by
              applicable law, an App Distributor will have no other warranty
              obligation whatsoever with respect to the mobile application;
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              (d) You represent and warrant that (i) you are not located in a
              country that is subject to a U.S. government embargo, or that has
              been designated by the U.S. government as a “terrorist supporting”
              country; and (ii) you are not listed on any U.S. government list
              of prohibited or restricted parties;
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              (e) You must comply with applicable third-party terms of agreement
              when using the mobile application, e.g., if you have a VoIP
              application, then you must not be in breach of their wireless data
              service agreement when using the mobile application;
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              (f) You acknowledge and agree that the App Distributors are third
              party beneficiaries of these Terms and Conditions, and that each
              App Distributor will have the right (and will be deemed to have
              accepted the right) to enforce these Terms and Conditions against
              you as a third-party beneficiary thereof.
            </p>

            {/* NEW SECTION 12 INSERTED HERE */}
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>12. Viulive Child Safety Standards and Compliance</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              Viulive is committed to maintaining a safe digital environment
              that protects children from all forms of sexual abuse and
              exploitation.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>
                12.1 Published Standards Against Child Sexual Abuse and
                Exploitation (CSAE)
              </b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              Viulive strictly prohibits any content that promotes, depicts, or
              facilitates child sexual abuse and exploitation. This includes but
              is not limited to:
            </p>
            <ul className="termsList">
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Sharing, distributing, or creating child sexual abuse material
                (CSAM);
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Engaging in grooming, coercion, or solicitation of minors;
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Using the platform for human trafficking or exploitation
                purposes.
              </li>
            </ul>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              We actively monitor and remove any content that violates these
              standards and report such activities to the relevant law
              enforcement agencies.
            </p>

            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>12.2 In-App Mechanism for User Feedback and Reporting</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              To ensure a safe environment, Viulive provides an in-app reporting
              system where users can report:
            </p>
            <ul className="termsList">
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Suspicious activities related to CSAE;
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Inappropriate content involving minors;
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Any violations of our community guidelines related to child
                safety.
              </li>
            </ul>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              Users can report violations through the 'Report' button available
              on all live streams, and uploaded content. Reports are reviewed
              promptly by our moderation team, and appropriate actions are
              taken, including content removal, user bans, and legal reporting
              where necessary.
            </p>

            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>12.3 Compliance with Child Safety Laws</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              Viulive complies with international and local child protection
              laws, works closely with child protection agencies and law
              enforcement authorities to ensure compliance with all applicable
              child safety laws and regulations.
            </p>

            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>12.4 Addressing Child Sexual Abuse Material (CSAM)</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              Viulive has strict measures to prevent, detect, and remove CSAM
              from its platform, including:
            </p>
            <ul className="termsList">
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Human moderation team reviewing flagged content;
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Reporting to organizations such as the National Center for
                Missing & Exploited Children (NCMEC) and track CSAM-related
                activities.
              </li>
            </ul>

            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>12.5 Enforcement and Consequences</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              Any violation of our child safety standards will result in
              immediate action, which may include:
            </p>
            <ul className="termsList">
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Permanent account suspension;
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Removal of all associated content;
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Legal action and cooperation with law enforcement agencies.
              </li>
            </ul>

            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>12.6 Contact Information for Child Safety Concerns</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              For any concerns related to child safety on Viulive, users can
              contact our dedicated child safety team at:{" "}
              <a
                className="termLink"
                target="_blank"
                href="mailto:info@viulive.com"
                rel="noopener noreferrer"
              >
                info@viulive.com
              </a>
            </p>
            {/* END OF NEW SECTION 12 */}

            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>13. General</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              13.1 Visiting the Site, sending us emails, and completing online
              forms constitute electronic communications. You consent to receive
              electronic communications and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communication be in writing.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b className="subHeaderterms">
                You hereby agree to the use of electronic signatures, contracts,
                orders and other records and to electronic delivery of notices,
                policies and records of transactions initiated or completed by
                us or via the Site
              </b>
              . You hereby waive any rights or requirements under any statutes,
              regulations, rules, ordinances or other laws in any jurisdiction
              which require an original signature or delivery or retention of
              non-electronic records, or to payments or the granting of credits
              by other than electronic means.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              13.2 These Terms and Conditions and any policies or operating
              rules posted by us on the Site or in respect to the Services
              constitute the entire agreement and understanding between you and
              us.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              13.3 Our failure to exercise or enforce any right or provision of
              these Terms and Conditions shall not operate as a waiver of such
              right or provision.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              13.4 We may assign any or all of our rights and obligations to
              others at any time.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              13.5 We shall not be responsible or liable for any loss, damage,
              delay or failure to act caused by any cause beyond our reasonable
              control.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              13.6 If any provision or part of a provision of these Terms and
              Conditions is unlawful, void or unenforceable, that provision or
              part of the provision is deemed severable from these Terms and
              Conditions and does not affect the validity and enforceability of
              any remaining provisions.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              13.7 There is no joint venture, partnership, employment or agency
              relationship created between you and us as a result of these Terms
              and Conditions or use of the Site or Services.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              13.8 A person who is not a party to these Terms and Conditions
              shall have no right under the Contracts (Rights of Third Parties)
              Act 1999 to enforce any term of these Terms and Conditions.
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              13.9 In order to resolve a complaint regarding the Services or to
              receive further information regarding use of the Services, please
              contact us by email at{" "}
              <a
                className="termLink"
                target="_blank"
                href="mailto:info@viulive.com"
                rel="noopener noreferrer"
              >
                info@viulive.com
              </a>
              .
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              <b>14. Use Policy</b>
            </p>
            <p
              className={`termsContent ${
                this.props.inRegister ? "registerTermsContent" : ""
              }`}
            >
              Violating any of the items listed below is a violation of this Use
              Policy &amp; the Terms and Agreements. Your account would be
              subject to termination and you would be liable in case you have
              caused any harms and damages to anyone involved.
            </p>
            <ol>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Systematically retrieve data or other content from the Site to a
                compile database or directory without written permission from us
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Make any unauthorized use of the Site, including collecting
                usernames and/or email addresses of users to send unsolicited
                email or creating user accounts under false pretenses
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Use a buying agent or purchasing agent to make purchases on the
                Site
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Use the Site to advertise or sell goods and services
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Circumvent, disable, or otherwise interfere with
                security-related features of the Site, including features that
                prevent or restrict the use or copying of any content or enforce
                limitations on the use
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Engage in unauthorized framing of or linking to the Site
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive account information such as user
                passwords
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Make improper use of our support services, or submit false
                reports of abuse or misconduct
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Interfere with, disrupt, or create an undue burden on the Site
                or the networks and services connected to the Site
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Attempt to impersonate another user or person, or use the
                username of another user
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Sell or otherwise transfer your profile
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Use any information obtained from the Site in order to harass,
                abuse, or harm another person
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Use the Site or our content as part of any effort to compete
                with us or to create a revenue-generating endeavor or commercial
                enterprise
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of the Site
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Attempt to access any portions of the Site that you are
                restricted from accessing
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Harass, annoy, intimidate, or threaten any of our employees,
                agents, or other users
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Use of abusive language by users
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Delete the copyright or other proprietary rights notice from any
                of the content
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Copy or adapt the Site’s software, including but not limited to
                Flash, PHP, HTML, JavaScript, or other code
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material that interferes with
                any party’s uninterrupted use and enjoyment of the Site, or any
                material that acts as a passive or active information collection
                or transmission mechanism
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Use, launch, or engage in any automated use of the system, such
                as using scripts to send comments or messages, robots, scrapers,
                offline readers, or similar data gathering and extraction tools
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Disparage, tarnish, or otherwise harm, in our opinion, us and/or
                the Site
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Use the Site in a manner inconsistent with any applicable laws
                or regulations
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Threaten users with negative feedback or offering services
                solely to give positive feedback to users
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Misrepresent experience, skills, or information about a User
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Advertise products or services considered illegal or harmful
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Post or live stream any inappropriate or poor images or texts
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Post, live stream or suggest any racial or offensive images or
                texts or comments
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Solicit sexual services
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                Post or livestream any copyrighted or unauthorized images or
                videos
              </li>
              <li
                className={`termsContent ${
                  this.props.inRegister ? "registerTermsContent" : ""
                }`}
              >
                ViuLive does not guarantee maintaining, archiving, protecting or
                indefinitely keeping your images/videos or any postings for
                anything you have posted on ViuLive. We recommend that you keep
                a backup of all your materials. Videos with poor quality and or
                very low view ratings may be removed at any time.
              </li>
            </ol>
          </div>
        </div>
      </>
    );
  }
}

export default termsAndConditions;
